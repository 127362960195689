import { DesignBG } from "../../images/Services/Backgrounds"

import {
  IoTLogo,
  IoT1,
  IoT2,
  IoT3,
  OnlineShopLogo,
  OnlineShop1,
  OnlineShop2,
  OnlineShop3,
  PropertyAppLogo,
  PropertyApp1,
  PropertyApp2,
  PropertyApp3,
} from "../../images/Services/Design"

const dataForDesign = {
  service: "design",
  header: {
    title: "Design",
    background: DesignBG,
  },
  design: [
    {
      id: 1,
      header: {
        logo: PropertyAppLogo,
      },
      articles: {
        texts: [
          "The Property Management App  includes tools to help users and property administrators manage reporting, budgeting, public utility charges, in-house services payments, document storage, text messaging and news posting. Users can also handle maintenance requests including scheduling, tracking and feedback. This multi-functional portal allows residents to check balances, pay utility charges and fees online, send maintenance requests and receive property alerts.<br><br>",
        ],
        accents: [
          "Online Resident Portal",
          "Dashboard ",
          "Navigation tree",
          "Web-based multitasking",
          "Payments Center",
          "View tenants profiles",
          "Invoices",
        ],
        images: [
          {
            id: 1,
            path: PropertyApp1,
          },
          {
            id: 2,
            path: PropertyApp2,
          },
          {
            id: 3,
            path: PropertyApp3,
          },
        ],
      },
    },
    {
      id: 2,
      header: {
        logo: OnlineShopLogo,
      },
      articles: {
        texts: [
          "Smaller eCommerce sites have the luxury of not having to follow the pre-defined design styles that many larger corporate stores tend to use. They really don’t need to have a content-heavy landing page, a mega-menu with hundreds of categories, or even a section for suggested or alternative products.",
          "What smaller eCommerce stores need is a great designer to build a simple and modern site around their prized products.<br><br>Many smaller stores owners  still think that they need to look like one of the big boys. That really is not case. Let us prove it!<br><br>",
        ],
        accents: [
          "Low operational costs",
          "Increased revenue",
          "Greater customer acquisition",
          "Social recommendation",
          "Customer buying analytics",
          "Full advantage of customer advocacy",
          "Larger marketing ROI",
        ],
        images: [
          {
            id: 1,
            path: OnlineShop1,
          },
          {
            id: 2,
            path: OnlineShop2,
          },
          {
            id: 3,
            path: OnlineShop3,
          },
        ],
      },
    },
    {
      id: 3,
      header: {
        logo: IoTLogo,
      },
      articles: {
        texts: [
          "The application allows comparing ongoing equipment performance against pre-set standards to identify anomalies.<br><br>Industrial manufacturers are facing extra maintenance, service and lost production costs when heavy machinery unexpectedly stops. Primary business concerns are related to reduced costs of operations through reduced maintenance costs as well as improved reliability of operations to allow for more robust supply chain<br><br>",
        ],
        accents: [
          "SmartSensor connected to industrial equipment transfers generated data metrics to the cloud",
          "Data is continuously analysed",
          "Alerts areraised against any anomalies and sent to engineer’s mobile phone or email",
          "Scheduled or ad- hoc reports on single or batch equipment activity",
        ],
        images: [
          {
            id: 1,
            path: IoT1,
          },
          {
            id: 2,
            path: IoT2,
          },
          {
            id: 3,
            path: IoT3,
          },
        ],
      },
    },
  ],
}

export default dataForDesign

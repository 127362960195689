import React, { useState, useEffect } from "react"

import { Link } from "gatsby"

import "./PortfolioHeader.scss"

export default function PortfolioHeader({ title }) {
  const [location, setLocation] = useState("All")

  useEffect(() => {
    setLocation(window.location.search)
  })
  return (
    <section className="portfolio-header-container">
      <div className="container">
        <div className="row">
          <h1 className="portfolio-header-container-title col-lg-8 col-md-10 col-sm-9">
            {title}
          </h1>
          <ul className="col-lg-7 col-md-7 col-sm-12 col-xs-12 portfolio-header-container-nav">
            <li className="">
              <Link to="/cases/" activeClassName="active">
                #all
              </Link>
            </li>
            <li className="">
              <Link
                to="/cases/?platform=desktop/"
                className={location === "?platform=desktop" ? "active" : null}
              >
                #desktop
              </Link>
            </li>
            <li className="">
              <Link
                to="/cases/?platform=mobile/"
                className={location === "?platform=mobile" ? "active" : null}
              >
                #mobile
              </Link>
            </li>
            <li className="">
              <Link
                to="/cases/?platform=web/"
                className={location === "?platform=web" ? "active" : null}
              >
                #web
              </Link>
            </li>
            <li className="">
              <Link
                to="/cases/?platform=webRTC/"
                className={location === "?platform=webRTC" ? "active" : null}
              >
                #WebRTC
              </Link>
            </li>
            <li className="">
              <Link to="/cases/design/" className="active">
                #design
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

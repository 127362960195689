import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import PortfolioHeader from "../../components/PortfolioPage/PortfolioHeader"
import DesignRoadmap from "../../components/Design/DesignRoadmap"
import LatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import WorkTogetherSection from "../../components/Services/WorkTogetherSection"
import dataForDesign from "../../information/Services/dataForDesign"
import { mainUrl } from "../../js/config"
import "../../components/SingleService/DedicatedTeam/TeamRoadmap.scss"

export default function Startups({ location }) {
  const { design } = dataForDesign
  return (
    <section className="startup">
      <SEO
        title="UI/UX Design Development Cases — OS-System"
        description="Custom UI/UX solutions cases. Our expert designers have not only the current capabilities you need but also the ability to build new ones rapidly."
        canonical={`${mainUrl}/cases/design/`}
      />
      <Layout>
        <PortfolioHeader location={location} title="Design" />
        <DesignRoadmap design={design} />
        <LatestProjects />
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}

import React from "react"

import Tick from "../../../images/Services/Design/tick.png"
import ArrowBtn from "../../../images/Services/Design/arrowBtn.png"

import "./DesignRoadmap.scss"

export default function DesignRoadmap({ design }) {
  const onLeftButtonClick = project => {
    const images = document.querySelectorAll(
      `.design__project-item:nth-child(${project}) .image-wrapper`
    )
    const imageWrapper = document.querySelector(
      `.design__project-item:nth-child(${project}) .images-wrapper`
    )
    imageWrapper.insertBefore(images[2], images[0])
  }
  const onRightButtonClick = project => {
    const images = document.querySelectorAll(
      `.design__project-item:nth-child(${project}) .image-wrapper`
    )
    const imageWrapper = document.querySelector(
      `.design__project-item:nth-child(${project}) .images-wrapper`
    )
    imageWrapper.insertBefore(images[0], null)
  }

  return (
    <section className="design">
      <article className="design__project">
        {design.map(project => (
          <div key={project.id} className="design__project-item">
            <header>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={project.header.logo}
                  className="design__project-item__logo"
                  alt="Project"
                />
              </div>
            </header>
            <div className="container">
              <div className="design__project-item__content">
                <div className="design__project-item__content-desсription">
                  {project.articles.texts.map((text, idx, arr) => (
                    <div
                      key={idx}
                      className={
                        arr.length === 2
                          ? "col-lg-4 col-md-4 col-sm-7 col-xs-12"
                          : "col-lg-6 col-md-6 col-sm-7 col-xs-12"
                      }
                    >
                      <div
                        className="design__project-item__content-desсription-text"
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    </div>
                  ))}
                  <ul className="col-flex col-lg-6 col-md-5 col-sm-5 col-xs-12">
                    {project.articles.accents.map((accent, idx) => (
                      <li key={idx}>
                        <img src={Tick} />
                        <p>{accent}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="design__project-item__content-images">
                  <div className="images-wrapper">
                    {project.articles.images.map(image => (
                      <div key={image.id} className="image-wrapper">
                        <img src={image.path} alt='Our app screen' />
                      </div>
                    ))}
                  </div>
                  <div className="button-wrapper">
                    <a
                      onClick={() => onLeftButtonClick(project.id)}
                      data-action="slideLeft"
                    >
                      <img src={ArrowBtn} />
                    </a>
                    <a
                      onClick={() => onRightButtonClick(project.id)}
                      data-action="slideRight"
                    >
                      <img src={ArrowBtn} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </article>
    </section>
  )
}
